import React from 'react'
import Navigation from './TopHeader/Navigation'
import Header from './TopHeader/Header'
import { FiShoppingCart } from "react-icons/fi";
export default function Cart() {
  return (
    <>
    <Navigation/>
      <Header/>

      <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"90vh"}}>
      <FiShoppingCart  size={100} color='#95a5a6'/>
      <h3 style={{marginLeft:15,color:"#95a5a6"}}>Not Product Available</h3>
      </div>
    </>
  )
}
