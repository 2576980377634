import React from 'react'
import "../../componentCss/Header.css"
import { useNavigate } from 'react-router-dom'


export default function Header() {

const navigation=  useNavigate()

  return (
    <div  className='TopHeader'>
         <a href='#' style={{fontSize:12,textDecoration:"none",color:"#434d5a",fontWeight:"500",letterSpacing:0.3}} onClick={()=>navigation('/About')}>
            ABOUT US
         </a>

         <a href='#' style={{fontSize:12,textDecoration:"none",color:"#434d5a",fontWeight:"500",letterSpacing:0.3,marginLeft:15}}>
            ALL DATA
         </a>

         <a href='#' style={{fontSize:12,textDecoration:"none",color:"#434d5a",fontWeight:"500",letterSpacing:0.3,marginLeft:15}}>
            ALL INDIA DATA
         </a>

         <select name="CITY/STATE" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="CITY/STATE">CITY/STATE</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>

<select name="INDUSTRY" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="INDUSTRY">INDUSTRY</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>
 
<select name="INDUSTRY" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="EXHIBITOR">EXHIBITOR</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>

<select name="INDUSTRY" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="INDUSTRY">EMIAL</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>

<select name="INDUSTRY" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="INDUSTRY">RETAILERS</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>

<select name="INDUSTRY" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="INDUSTRY">MSMA DATA</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>

<select name="INDUSTRY" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="INDUSTRY">STARTUPS</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>

<select name="INDUSTRY" id="cars" style={{padding:10,marginLeft:15,border:"none",fontSize:12,fontWeight:"500",outline:"none"}}>
  <option value="INDUSTRY">B2C DATA</option>
  <option value="saab">Saab</option>
  <option value="mercedes">Mercedes</option>
  <option value="audi">Audi</option>
</select>
<a href='#' style={{fontSize:12,textDecoration:"none",color:"#434d5a",fontWeight:"500",letterSpacing:0.3,marginLeft:15}}>
            CONTACT US
         </a>
    </div>
  )
}
