import React from 'react'
import "../componentCss/Advance.css"
import { Container ,Row,Col} from 'react-bootstrap'
import one from "../assets/1.png"
import two from "../assets/2.png"
import three from "../assets/3.png"
import four from "../assets/4.png"

export default function Advance() {
  return (
    <div>
        <br></br><br></br>
<button className='mx-auto d-block' style={{height:40,border:"none",padding:10,fontSize:12,backgroundColor:"#991b1e",color:"#fff",fontWeight:"500"}}>SEE ALL PRODUCTS</button>
<br></br><br></br>
<h2 style={{textAlign:"center",fontWeight:"700",fontSize:45}}> <span  style={{color:"#2d2a2a"}}>Advantage</span> <span style={{color:"#BD0000"}}>Lead</span><span style={{color:"#6f6f6f"}}>Bank</span> </h2>

<Container style={{marginTop:50}}>
    <Row>
        <Col md={3} >
        <img src={three} style={{width:150,height:150}} className='mx-auto d-block'/>

        <h3 style={{textAlign:"center",marginTop:30,fontWeight:"700"}}>Authentic</h3>
        
        </Col>
        <Col md={3}>
        <img src={two} style={{width:150,height:150}} className='mx-auto d-block'/>
        <h3 style={{textAlign:"center",marginTop:30,fontWeight:"700"}}>Relevant</h3>
        </Col>
        <Col md={3}>
        <img src={one} style={{width:150,height:150}} className='mx-auto d-block'/>
        <h3 style={{textAlign:"center",marginTop:30,fontWeight:"700"}}>Verified</h3>
        </Col>
        <Col md={3}>
        <img src={four} style={{width:150,height:150}} className='mx-auto d-block'/>
        <h3 style={{textAlign:"center",marginTop:30,fontWeight:"700"}}>User Oriented</h3>
        </Col>
    </Row>

</Container>
    </div>
  )
}
