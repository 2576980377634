import React,{useState} from 'react'
import '../../componentCss/Navigation.css'
import { Form } from 'react-bootstrap'
import { FiSearch,FiUser,FiShoppingCart   } from "react-icons/fi";
import { CgMenu } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';

export default function Navigation() {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }
  const[selectActive,setSelectActive] =useState('menu')

  const navigation = useNavigate()

  return (
    <>
    <div style={{display:"flex",justifyContent:"center"}}>
    <div className='topHeader'>
    <div style={{width:"100%",display:"flex"}}>
     <div style={{width:"20%",display:"flex",justifyContent:"center",alignItems:"center"}} onClick={()=>navigation('/')}>
        <img src="https://leadbank.in/wp-content/uploads/2024/05/leadbank-logo.png" style={{width:210,height:70}}/>
    


        </div>    
        <div style={{width:"60%",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div style={{width:"100%",height:40,border:"1px solid #e9e9e9",display:"flex",}}>
                <input placeholder='Search Product'  style={{width:"70%",height:"100%",outline:"none",border:"none",paddingLeft:20,fontSize:14}}/>
                
                <Form.Select aria-label="Default select example" style={{width:"25%",fontSize:12}}>
      <option>SELECT CATEGORY</option>
      <option value="All Data">All Data</option>
      <option value="Email">Email</option>
      <option value="Trade Show">Trade Show</option>
      <option value="All India">All India</option>
      <option value="Industry">Industry</option>
      <option value="City State">City State</option>
      <option value="B2C">B2C</option>
    </Form.Select>
          <FiSearch style={{marginLeft:10,marginTop:10}} size={18}/>
            </div>
    
        </div>
        <div style={{width:"20%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <FiUser size={18} style={{marginRight:10}}/>
        <FiSearch size={18} style={{marginRight:10}}/>
        <div style={{height:40,display:"flex",alignItems:"center"}}>
            
        <FiShoppingCart size={18} style={{marginRight:2}} onClick={()=>navigation('/Cart')} />
        <div style={{width:15,height:15,background:"#c0392b",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:100,fontSize:10,color:"#fff",marginTop:-11,marginRight:-5}}>
          0
        </div>

         <h6 style={{marginLeft:10,marginTop:10}}>0.0</h6>
        </div>

        </div>
      </div>
        </div>
    </div>

    <div  className='mobile-nav'>
      <div style={{width:"30%",height:50,display:"flex",alignItems:"center"}}>
      <CgMenu onClick={()=>handleShowNavbar()} />
      </div>

      <div style={{width:"30%",height:50,display:"flex",justifyContent:"center",alignItems:"center"}} onClick={()=>navigation('/')}>
      <img src="https://leadbank.in/wp-content/uploads/2024/05/leadbank-logo.png" style={{width:150,height:50}}/>
</div>
<div style={{width:"30%",height:50,display:"flex",justifyContent:"end",alignItems:"center"}}>
  
        <FiSearch size={18} style={{marginRight:10}}/>
        <div style={{height:40,display:"flex",alignItems:"center"}}>
            
        <FiShoppingCart size={18} style={{marginRight:2}} onClick={()=>navigation('/Cart')}/>
        <span style={{fontSize:10,background:"#bd0000",width:15,height:15,alignItems:"center",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:100,color:"#fff"}}>1</span>
       

        
        </div>
</div>
    </div>

    <div className={`nav-elements-mo  ${showNavbar && 'active'}`}>
      <div style={{width:"100%",height:50,display:"flex",}}>
        <div style={{width:"80%",display:"flex",height:50,justifyContent:"center",alignItems:"center",boxSizing:"border-box"}}>
          <input placeholder='Search for products'  style={{width:"100%",height:"100%",background:"transparent",color:"#fff",border:"none",paddingLeft:10}} />

        </div>
        <div style={{width:"10%",display:"flex",height:50,justifyContent:"center",alignItems:"center",boxSizing:"border-box"}}>
          <FiSearch  color='#fff' size={25}/>
        </div>
         
      </div>
         
         <div style={{width:"100%",height:50,display:"flex",justifyContent:"space-between"}}>
          <div style={{width:"50%",height:50,display:"flex",justifyContent:"center",alignItems:"center",color:"#fff",borderBottom:selectActive ==="menu"? "2px solid red":"transparent"}} onClick={()=>setSelectActive('menu')}>
            MENU
          </div>
          <div style={{width:"50%",height:50,display:"flex",justifyContent:"center",alignItems:"center",color:"#fff",borderBottom:selectActive ==="category"? "2px solid red":"transparent"}} onClick={()=>setSelectActive('category')}>
            CATEGORY
          </div>

         </div>


         {
            selectActive === "menu" ?
            <>
            <div  onClick={()=>navigation('/About')} style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            About
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            ALL DATA
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            ALL INDIA DATA
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            CITY/STATE
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            INDUSTRY
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            EXHIBITOR
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            EMAILS
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            RETAILERS
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            MSME DATA
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            STARTUPS
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            B2C DATA
            </div>
            <div style={{width:"100%",height:50,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            CONTACT US
            </div>
            
            </>

            :
            <>
              <div style={{width:"100%",height:100,borderBottom:"1px solid #B4AEAE",display:"flex",alignItems:"center",paddingLeft:20,color:"#fff",fontSize:13}}>
            Set your categories menu in theme setting -> Header -> Menu -> Mobile menu(categories)
            </div>
            </>




         }


    </div>
    </>
  )
}
