import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../componentCss/Shop.css"
import { useNavigate } from 'react-router-dom';

export default function Shop() {
  const cards = [
    { id: 1, title: 'Card 1', content: 'Contrary to popular belief, Lorem Ipsum is not simply random text.  ' ,img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzLMqttWZQoOLcTSp0Q0ru3L_Ji3twen0OSQ&s" ,prize:"1250"},
    { id: 2, title: 'Card 2', content: 'it is a long established fact that a reader will be distracted ',img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn7UZG_lY1l-I1bPL2iDtbUdrrOSBbPJGzuw&s",prize:"1050" },
    { id: 3, title: 'Card 3', content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. .',img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg1ZXQfxJtDSiYaJ6SJuNOo2lqEPSatgqoBQ&s",prize:"800" },
    { id: 4, title: 'Card 4', content: 'There are many variations of passages of Lorem Ipsum available, ',img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ1jrhLW4Or6c6wDSqKYMa_vHd6mKNIVddeYCZSfR38if3M-97-LE4HGB0MCspZiWrUQo&usqp=CAU",prize:"750" },
    { id: 5, title: 'Card 5', content: 'It is a long established fact that a reader will be ',img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiNzRQlQe3sTRIOjfbHEEDrDfMqWiFN9C19uy0yKZw2lyiY263_7HT5_DgdY_hrC2R4PM&usqp=CAU",prize:"1800" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
 centerPadding: '10px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  const sliderRef = React.createRef();

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const navigation = useNavigate()

  return (
    <div className='shopContainer'>
       
        <br></br><br></br>
        <div className="card-slider-container">
          <div className='computer-view'>
      <Slider ref={sliderRef} {...settings}>
        {cards.map((card) => (
          <div key={card.id} className="card" onClick={()=>navigation('/ProductDetail')}>
            <div className='img-card'>
              <img src={card.img} className='img-item' />

            </div>
            <p style={{fontSize:12,marginTop:20}}>{card.content}</p>
            <h6 style={{color:"#991b1e"}}>₹ {card.prize}</h6>
           <div className='cartBtn'>
            <button className='btn'> 
              <div className='cart-text'>
                Add To Cart
              </div>
              <div className='cart-icon'>
                Add To Cart
              </div>
            </button>

           </div>
          </div>
        ))}
      </Slider>
      </div>

  <div className='mobile-view'>
  <div class="grid-container">
  
           {cards.map((card) => (
            <div class="grid-item" onClick={()=>navigation('/ProductDetail')}>
               <div className='img-card'>
              <img src={card.img} className='img-item' />

            </div>
            <p style={{fontSize:12,marginTop:20}}>{card.content}</p>
            <h6 style={{color:"#991b1e"}}>₹ {card.prize}</h6>
            <div className='cartBtn'>
            <button className='btn'> 
              <div className='cart-text'>
                Add To Cart
              </div>
              <div className='cart-icon'>
                Add To Cart
              </div>
            </button>

           </div>
            </div>
          ))}

  
 
 
</div>
   



  </div>

    
    </div>
        <br></br> <br></br>
    
        
    </div>
  )
}
