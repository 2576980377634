import Advance from './component/Advance';
import Banner from './component/Banner';
import Footer from './component/Footer';
import Shop from './component/Shop';
import StudentChat from './component/StudentChat';
import Header from './component/TopHeader/Header';
import Navigation from './component/TopHeader/Navigation';
import logo from './logo.svg';
import Home from './component/Home';
import NoPage from './component/NoPage';
import ProductDetail from './component/ProductDetail';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cart from './component/Cart';
import About from './component/About';


function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NoPage />} />
        <Route path="/ProductDetail" element={<ProductDetail />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
