import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'

export default function Footer() {
  return (
    <div style={{borderTop:"2px solid #f0f0f0"}}>
        <Container>
            <Row>
                <Col>
                  <p style={{marginTop:20,color:"#868686",fontSize:14}}>Leadbank @ DTH Retail,</p>
                </Col>
                <Col style={{display:"flex",justifyContent:"end"}}>
                <p style={{marginTop:20,color:"#868686",fontSize:14}}>Privacy Policy | Cookies Policy | Return Policy | Terms of Use</p>
                
                </Col>
            </Row>

        </Container>



    </div>
  )
}
