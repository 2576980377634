import React from 'react'
import "../componentCss/Banner.css"

export default function Banner() {
  return (
    <div  className='banner'>
        <img src="https://leadbank.in/wp-content/uploads/2024/06/Banner.png" className='bannerImg'/>


    </div>
  )
}
