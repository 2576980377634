import React from 'react'

export default function NoPage() {
  return (
    <div>
 <h1>404 Not found</h1>

    </div>
  )
}
