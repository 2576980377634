import React from 'react'
import Navigation from './TopHeader/Navigation'
import Header from './TopHeader/Header'
import Banner from './Banner'
import Shop from './Shop'
import Advance from './Advance'
import Footer from './Footer'

export default function Home() {
  return (
    <>
  <Navigation/>
  <Header/>
 
  <Banner/>
  <br></br><br></br><br></br><br></br>
  <h2 style={{textAlign:"center",fontWeight:"700",fontSize:45}}><span style={{color:"#BD0000"}}>Lead</span><span style={{color:"#6f6f6f"}}>Bank</span> <span  style={{color:"#2d2a2a"}}>Shop</span></h2>
  <Shop/>
  <Advance/>
  <br></br>
  <Footer/>
  
    </>
  )
}
