import React from 'react'
import Navigation from './TopHeader/Navigation'
import Header from './TopHeader/Header'
import { Container,Row,Col } from 'react-bootstrap'
import "../componentCss/ProductDetail.css"
import { FiChevronRight ,FiFacebook,FiTwitter,FiMail} from "react-icons/fi";
import Footer from './Footer'
import Shop from './Shop'

export default function ProductDetail() {
  return (
    <>
    <Navigation/>
    <Header/>
    <br></br>
    <Container>
        <Row>
            <Col md={6} style={{display:"flex",boxSizing:"border-box"}}>

            <img src='https://images.ctfassets.net/p0qf7j048i0q/E3C718B6580F40C3B5C7D8D1E86B4A6E/f3dbff6e10f35df96a79d58b6039b954/i499343530.jpg?w=1200&q=75&h=630&fm=jpg' style={{width:"100%",height:400}}/>
            
            </Col>
            <Col  md={6}>
            <div className='nav-header'>
            <p style={{marginTop:10,color:"#868686",fontSize:13}}><span>Home</span> / <span>All Data</span> / <span>All India Educational Institutes</span></p>

            </div>
            <h2 style={{fontWeight:"600"}}>All India Educational Institutes</h2>
            <h6 style={{color:"#991b1e",fontSize:20}}>₹ 12000</h6>

            <Row  style={{borderBottom:"2px solid #f0f0f0"}}>
                <Col md={3} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}> About</p>
                </Col>
                <Col  md={9}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}> 	All India Data of School, Colleges, Other Educational Institutions, Online Education, Engineering & Medical Colleges, Play Schools
                Hobby Classes, Coaching Classes, Extra Curricular activities & Performing Art Institutes, Tuition Centers and Other Educational Instituutions</p>
                </Col>
            </Row>
            <Row  style={{borderBottom:"2px solid #f0f0f0"}}>
                <Col md={3} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}>Can be used for</p>
                </Col>
                <Col  md={9}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}> 	Can be approached for Marketing, Advertising, Branding Assignments & Projects
                as well as services and Products aimed for educational Institutions</p>
                </Col>
            </Row>

            <Row  style={{borderBottom:"2px solid #f0f0f0"}}>
                <Col md={3} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}>What You Get</p>
                </Col>
                <Col  md={9}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}> 	More Than 1.2 Lac+Data Split into Different Sheets<br></br>
                Includes Name, Address, Contact Person, Landline Number, Email Id, Mobile Numbers, Institution Type</p>
                </Col>
            </Row>

            <Row  style={{borderBottom:"2px solid #f0f0f0"}}>
                <Col md={3} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}>Suitable For</p>
                </Col>
                <Col  md={9}>
                <p style={{marginTop:10,color:"#868686",fontSize:13}}> Marketer, Advertising Agency, Stationary Companies, School supply companies, anyone having product or services related to schools, colleges and wish to approach them for the same.</p>
                </Col>
            </Row>
               <br></br>
            <h6>Fields Included in Database:</h6>

            <p style={{marginTop:10,color:"#868686",fontSize:13}}><span><FiChevronRight size={15}  style={{marginTop:-3}}/></span>   Institution Name</p>
            <p style={{marginTop:3,color:"#868686",fontSize:13}}><span><FiChevronRight size={15}  style={{marginTop:-3}}/></span>   Address</p>
            <p style={{marginTop:3,color:"#868686",fontSize:13}}><span><FiChevronRight size={15}  style={{marginTop:-3}}/></span>   Contact Person</p>
            <p style={{marginTop:3,color:"#868686",fontSize:13}}><span><FiChevronRight size={15}  style={{marginTop:-3}}/></span>   Contact Detail</p>
            <p style={{marginTop:3,color:"#868686",fontSize:13}}><span><FiChevronRight size={15}  style={{marginTop:-3}}/></span>  Email ID</p>
            <p style={{marginTop:3,color:"#868686",fontSize:13}}><span><FiChevronRight size={15}  style={{marginTop:-3}}/></span>  Landline/Mobile</p>

            <p style={{marginTop:10,color:"#868686",fontSize:13}}> Data Available for instant Download in Excel Format, In case of broken links Data will be mailed within 24 Hrs of Purchase.</p>
              
           <div style={{height:50,borderBottom:"2px solid #f0f0f0",display:"flex"}}>

            <div style={{width:100,display:"flex",border:"1px solid #e6e6e6",height:45,justifyContent:"space-between"}}>
              <div style={{width:"30%",display:"flex",justifyContent:"center",alignItems:"center",borderRight:"1px solid #e6e6e6"}}>
                -
              </div>
              <div  style={{width:"30%",display:"flex",justifyContent:"center",alignItems:"center"}}>1</div>
              <div  style={{width:"30%",display:"flex",justifyContent:"center",alignItems:"centerm",borderLeft:"1px solid #e6e6e6"}}>+</div>
            </div>

           <button style={{background:"#991b1e",width:150,height:45,border:"none",color:"#fff",marginLeft:10}}> 
              
                Add To Cart
             
            </button>

            
           </div>
           <br></br>
           <p style={{marginTop:10,color:"#868686",fontSize:13}}><span style={{color:"#000",fontWeight:"600"}}>Categories: </span> <span>All Data</span>, <span>All India</span>, <span>Industry</span></p>

           <p style={{marginTop:10,color:"#868686",fontSize:13}}><span style={{color:"#000",fontWeight:"600"}}>Tags: </span> <span>All india education data</span>, <span>All India Institutes Data</span></p>

           <p style={{marginTop:10,color:"#868686",fontSize:13}}><span style={{color:"#000",fontWeight:"600"}}>Share : </span>   <span><FiFacebook/></span>   <span><FiTwitter/></span></p>
            </Col>
        </Row>
   
    </Container>
    <hr></hr>

    <h6 style={{textAlign:"center",marginTop:10}}>Shipping & Delivery</h6>

    <h5 style={{marginTop:10,marginLeft:50,fontWeight:"600"}}>RELATED PRODUCTS</h5>

    <Shop/>

    <Footer/>
    </>
  )
}
