import React from 'react'
import Navigation from './TopHeader/Navigation'
import Header from './TopHeader/Header'
import "../componentCss/About.css"

export default function About() {
  return (
    <>
    <Navigation/>
    <Header/>

    <br></br><br></br>
    <h2 style={{textAlign:"center",fontWeight:"700",fontSize:45}}> <span  style={{color:"#2d2a2a"}}>About</span> <span style={{color:"#BD0000"}}>Lead</span><span style={{color:"#6f6f6f"}}>Bank</span> </h2>
    <p style={{textAlign:"center",fontSize:13,color:"#979694"}}>” Leadbank”  is a Lead acquisition and marketing platform owned and Managed by DTH Retail.</p>
    <p style={{textAlign:"center",fontSize:13,color:"#979694",padding:10}}>Our Founding Team’s 6 Years of Experience in Advertising, Marketing and specially Events & Exhibition Industry through our sister concern Sadaanpur Media helped us to identify the most common need of any event/Trade Show organizer which is “How to get sponsors, Exhibitors, Visitors & Speakers for their event and exhibition”. Every event organizer needs to be sure about this basic need, right at the time of conceptualization of the event and that is where we came to introduce ourselves.</p>

    <p style={{textAlign:"center",fontSize:13,color:"#979694",padding:10}}>We at “Leadbank” help event organisers in lead prospecting and acquisition by our custom-made services. Starting from creating and compiling just any industry specific database for your business development team  through web surfing to setting up a call center for you to acquire the sponsors, exhibitor, and guests for your event on turnkey basis – we do it all.</p>

    <p style={{textAlign:"center",fontSize:13,color:"#979694",padding:10}}>Leadbank also help both Event organisers & Exhibitors or Participants to come together by registering at www.leadbank.in as per industry of their interest by giving a common AI based registration & Response sharing platform.</p>

    <p style={{textAlign:"center",fontSize:13,color:"#979694",padding:10}}>DTH Retail has been working with small and big event management agencies and Trade show organisers in past and commands experience of acquiring more than 2000 sponsors & Exhibitors and more than 10 Lac+ guests & Visitors so far for various shows of our clients.</p>
    </>
  )
}
